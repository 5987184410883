var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcInputNumber from 'rc-input-number';
import UpOutlined from '@ant-design/icons/UpOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
const InputNumber = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const { className, size: customizeSize, prefixCls: customizePrefixCls, readOnly } = props, others = __rest(props, ["className", "size", "prefixCls", "readOnly"]);
    const prefixCls = getPrefixCls('input-number', customizePrefixCls);
    const upIcon = <UpOutlined className={`${prefixCls}-handler-up-inner`}/>;
    const downIcon = <DownOutlined className={`${prefixCls}-handler-down-inner`}/>;
    const mergeSize = customizeSize || size;
    const inputNumberClass = classNames({
        [`${prefixCls}-lg`]: mergeSize === 'large',
        [`${prefixCls}-sm`]: mergeSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-readonly`]: readOnly,
    }, className);
    return (<RcInputNumber ref={ref} className={inputNumberClass} upHandler={upIcon} downHandler={downIcon} prefixCls={prefixCls} readOnly={readOnly} {...others}/>);
});
InputNumber.defaultProps = {
    step: 1,
};
export default InputNumber;
